const formatNumberWithout = (number) => {
  try {
    const formattedNumber = number.toLocaleString();
    const [integerPart, decimalPart] = formattedNumber.split(".");
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedCurrency = decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
    return formattedCurrency;
  } catch (e) {
    console.error(e);
  }
};

function formatCurrencyInput(input) {
  const numericInput = input.replace(/[^0-9.]/g, "");
  const [integerPart, decimalPart] = numericInput.split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const formattedCurrency = decimalPart
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;
  return formattedCurrency;
}

const groupByCategoryName = (variations) => {
  const grouped = variations.reduce((acc, variation) => {
    const { category_name } = variation;
    if (!acc[category_name]) {
      acc[category_name] = [];
    }
    acc[category_name].push(variation);
    return acc;
  }, {});

  // Convert the object into an array of objects
  return Object.keys(grouped).map((categoryName) => ({
    category_name: categoryName,
    variations: grouped[categoryName],
  }));
};

const groupByDate = (transactions) => {
  // Reduce the transactions into an object grouped by the date
  const grouped = transactions.reduce((acc, transaction) => {
    const date = transaction.created_at.split(" ")[0]; // Extract the date part (YYYY-MM-DD)
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(transaction);
    return acc;
  }, {});

  // Convert the object into an array of objects
  return Object.keys(grouped).map((date) => ({
    created_at: date,
    transactions: grouped[date],
  }));
};

const hourformat = (tanggalISO) => {
  const options = {
    hour: "2-digit",
    minute: "2-digit",
  };

  const tanggal = new Date(tanggalISO);
  return tanggal.toLocaleString("id-ID", options);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("id-ID", options).format(date);
  return formattedDate;
};

const validateEmail = (email) => {
  const emailLower = email.toLowerCase();
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isValidFormat = re.test(emailLower);

  const commonErrors = {
    ".con": "Format tidak valid '.con', mungkin maksud Anda '.com'. ",
    ".cok": "Format tidak valid '.cok', mungkin maksud Anda '.com'. ",
    gamil: "Format tidak valid 'gamil', mungkin maksud Anda 'gmail'. ",
    gmial: "Format tidak valid 'gmial', mungkin maksud Anda 'gmail'. ",
    gmil: "Format tidak valid 'gmil', mungkin maksud Anda 'gmail'. ",
  };

  let errorMessage = "";

  if (isValidFormat) {
    for (const [error, message] of Object.entries(commonErrors)) {
      if (emailLower.includes(error)) {
        errorMessage += message;
      }
    }
  } else {
    errorMessage = "Format email tidak valid.";
  }

  return {
    isValid: isValidFormat && !errorMessage,
    error: errorMessage.trim(),
  };
};

const getNextMonthDate = (m) => {
  const today = new Date();
  const nextMonth = new Date(
    today.getFullYear(),
    today.getMonth() + m,
    today.getDate()
  );

  const options = { day: "2-digit", month: "short", year: "numeric" };
  return nextMonth.toLocaleDateString("en-US", options);
};

const removeZero = (inputString) => {
  const filteredValue = inputString.replace(/[^\d]/g, "");

  if (filteredValue.startsWith("0")) {
    let modifiedValue = filteredValue;

    if (modifiedValue.startsWith("62")) {
      modifiedValue = modifiedValue.slice(2);
    }

    return modifiedValue.slice(0, 14);
  } else {
    return "Input harus dimulai dengan angka '0'";
  }
};

const getTimeThreeDaysFromNow = () => {
  const now = new Date();

  const futureDate = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000);

  const hours = futureDate.getHours();
  const minutes = futureDate.getMinutes();
  const seconds = futureDate.getSeconds();

  return {
    hours,
    minutes,
    seconds,
  };
};

const calculateTotalPriceReal = (variationTicket) => {
  return `${variationTicket?.reduce(
    (total, item) => total + item.real_price,
    0
  )}000`;
};

const calculateTotalPrice = (variationTicket) => {
  return `${variationTicket?.reduce(
    (total, item) => total + item.price,
    0
  )}000`;
};

const calculateTotalQyt = (variationTicket) => {
  return variationTicket?.reduce(
    (total, item) => total + Number(item.allocated_person),
    0
  );
};

const calculateTotalDiscTicket = (variationTicket) => {
  return variationTicket?.reduce(
    (total, item) => total + Number(item.discount),
    0
  );
};

const calculateTotalDiscount = () => {
  return calculateTotalPrice();
};

const getLink = (link) => {
  return window.open(link, "_blank", "rel=noopener noreferrer");
};

function checkDay(data) {
  const hasId17 = data.some((item) => item.id === 17);
  const hasId18 = data.some((item) => item.id === 18);

  if (hasId17 && hasId18) {
    return 1;
  } else if (hasId17) {
    return 2;
  } else if (hasId18) {
    return 3;
  } else {
    return 4;
  }
}

export {
  getLink,
  checkDay,
  calculateTotalPriceReal,
  calculateTotalPrice,
  calculateTotalQyt,
  calculateTotalDiscTicket,
  calculateTotalDiscount,
  getTimeThreeDaysFromNow,
  formatNumberWithout,
  formatCurrencyInput,
  groupByCategoryName,
  hourformat,
  groupByDate,
  formatDate,
  validateEmail,
  getNextMonthDate,
  removeZero,
};
