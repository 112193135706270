import React, { useRef } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import { Suspense, lazy, useState } from "react";
import { formatNumberWithout, getLink } from "../helper";
import axios from "axios";
import { var_name } from "../components/utils";
import LoadingScreen from "./Loading/LoadingScreen";

const LazyImage = lazy(() => import("../components/functions/LazyImage"));

const Home = () => {
  const [csNumber, setCsNumber] = useState("6282336441318");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [modalSeatMap, setModalSeatMap] = useState(false);
  const [disableRes, setDisableRes] = useState(false);
  const [seatMap, setSeatMap] = useState([]);
  const [seatMapSelectId, setSeatMapSelectId] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const [selectedSeat, setSeatSelect] = useState("");
  const [seatMapTitle, setSeatMapTitle] = useState("");
  const [listSeatSelected, setListSeatSelected] = useState("");
  const [priceSeatSelected, setPriceSeatSelected] = useState("");
  const [selectedTicketId, setSelectedTicketId] = useState("");
  const [bookingData, setBookingData] = useState([]);
  const [optionSeat, setOptionSeat] = useState([]);
  const [seatBooked, setSeatBooked] = useState([]);
  const [bookingStatus, setBookingStatus] = useState(false);
  const [key, setKey] = useState("home");
  const section3Ref = useRef(null);

  const handleImageLoad = () => {
    setCsNumber();
    setImageLoaded(true);
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleBooking = async (e) => {
    e.preventDefault();

    if (document.getElementById("email").value === "") {
      return alert("Email Tidak Boleh Kosong");
    }

    const params = {
      email: document.getElementById("email").value,
    };

    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/seating/getBooking`,
        {
          headers: {
            "Content-Type": "application/json",
            token: process.env.REACT_APP_API_KEY,
          },
          method: "POST",
          data: JSON.stringify(params),
        }
      );

      if (response.data.status) {
        setCustomerData(response.data.data.data);
        setBookingData(response.data.data.booking);
        setSeatBooked(response.data.data.booked_seats);
        setBookingStatus(response.data.data.booking.length >= 0 ? true : false);
        setKey(
          `${response.data.data.booking[0].ticket_id}-${response.data.data.booking[0].booking_code}`
        );
        getRecomendation(
          `${response.data.data.booking[0].ticket_id}-${response.data.data.booking[0].booking_code}`
        );
      } else {
        alert("Tidak dapat mendapatkan data booking, Mohon Maaf");
      }
    } catch (error) {}
  };

  const getRecomendation = async (key) => {
    try {
      const keys = key.split("-");
      const ticket_id = keys[0].trim();
      const booking_id = keys[1].trim();
      const params = {
        ticket_id: ticket_id,
        booking_id: booking_id,
      };
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/seating/getRecomendation`,
        {
          headers: {
            "Content-Type": "application/json",
            token: process.env.REACT_APP_API_KEY,
          },
          method: "POST",
          data: JSON.stringify(params),
        }
      );

      if (response.data.status) {
        if (response.data.data.options.length >= 0) {
          setOptionSeat(response.data.data.options);
        } else {
          alert("Tidak ada kursi yang bisa di reservasi");
        }
      }
    } catch (error) {}
  };

  const handleReservSeat = async () => {
    if (seatMapSelectId.length > 0) {
      try {
        setDisableRes(true);
        const keys = key.split("-");
        const booking_id = keys[1].trim();

        const params = {
          seat_selected: seatMapSelectId.join(","),
          ticket_id: selectedTicketId,
          booking_id: booking_id,
          email: customerData?.email,
        };

        console.log(params);
        const response = await axios(
          `${process.env.REACT_APP_API_URL}/seating/selectSeat`,
          {
            headers: {
              "Content-Type": "application/json",
              token: process.env.REACT_APP_API_KEY,
            },
            method: "POST",
            data: JSON.stringify(params),
          }
        );

        if (response.data.status) {
          if (response.data.data.type === "free") {
            alert(
              "Kursi berhasil di reservasi, check email anda untuk melihat invoice reservasi kursi. Terimakasih"
            );
            setDisableRes(false);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else if (response.data.data.type === "paid") {
            setDisableRes(false);
            window.location.href = response.data.data.redirect;
          } else {
            setDisableRes(false);
            alert("Kursi Sudah Dipesan, Mohon Maaf");
          }
        }
      } catch (error) {}
    } else {
      alert("Tidak ada kursi yang di pilih");
    }
  };

  const getSeatMap = async (ticket_id, loc) => {
    try {
      const params = {
        ticket_id: ticket_id,
        loc2stage: loc,
      };
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/seating/getDataMaps`,
        {
          headers: {
            "Content-Type": "application/json",
            token: process.env.REACT_APP_API_KEY,
          },
          method: "POST",
          data: JSON.stringify(params),
        }
      );

      if (response.data.status) {
        setSeatMap(Object.entries(response.data.data));
        setModalSeatMap(true);
        const item = var_name.find((item) => item.id === ticket_id);

        setSeatMapTitle(
          `${item.name} - ${loc === "left" ? "A" : loc === "right" ? "B" : ""}`
        );
      }
    } catch (error) {}
  };

  function getAllNames(options) {
    const names = [];
    options.forEach((group) => {
      names.push(
        `${group.name} ${
          group.loc2stage === "right"
            ? "(B)"
            : group.loc2stage === "left"
            ? "(A)"
            : group.loc2stage
            ? group.loc2stage
            : ""
        }`
      );
    });

    return names.join(", ");
  }

  function getAllId(options) {
    const ids = [];
    options.forEach((group) => {
      ids.push(group.id);
    });

    setSeatMapSelectId(ids);
  }

  function getAllNamesNoLoc(options) {
    const names = [];
    options.forEach((group) => {
      names.push(group.name);
    });

    return names.join(",");
  }

  function getTotalPrice(options) {
    let totalSeatCharged = 0;

    options.forEach((group) => {
      if (group.seat_charged !== null) {
        // Jika seat_charged tidak null
        totalSeatCharged += parseFloat(group.seat_charged); // Menambahkan nilai seat_charged ke total
      }
    });

    return totalSeatCharged;
  }

  const handleSelectSeat = (id, seatSelect, ticket_id, price, item) => {
    setSeatSelect(id);
    setListSeatSelected(seatSelect);
    setPriceSeatSelected(price);
    setSelectedTicketId(ticket_id);
    scrollToSection(section3Ref);
    getAllId(item);
  };

  const bgColor = (stt, id) => {
    let seatClass = "";
    if (stt === "1" && seatMapSelectId.includes(id)) {
      seatClass = "green";
    } else if (stt === "3") {
      seatClass = "red";
    } else if (stt === "0") {
      seatClass = "red";
    } else if (stt === "2") {
      seatClass = "red";
    } else {
      seatClass = "white";
    }

    return seatClass;
  };

  const borderColor = (stt, id) => {
    let seatClass = "";
    if (stt === "1" && seatMapSelectId.includes(id)) {
      seatClass = "green";
    } else if (stt === "3") {
      seatClass = "red";
    } else if (stt === "0") {
      seatClass = "red";
    } else if (stt === "2") {
      seatClass = "red";
    } else {
      seatClass = "black";
    }

    return seatClass;
  };

  return (
    <Container className="home text-white" fluid>
      <Modal
        size="xl"
        centered
        show={modalSeatMap}
        onHide={() => setModalSeatMap(false)}
      >
        <Modal.Header closeButton>
          <b>{seatMapTitle}</b>
        </Modal.Header>
        <Modal.Body style={{ overflowX: "auto", overflowY: "auto" }}>
          <Col style={{ width: "100%", height: "500px" }}>
            {seatMap?.map(([item, seat], i) => (
              <Col className="d-flex" key={i}>
                {seat?.map((items, index) => (
                  <Col
                    style={{
                      backgroundColor: bgColor(items.status, items.id),
                      border: `1px solid ${borderColor(
                        items.status,
                        items.id
                      )}`,
                      margin: "2px",
                      width: "3rem",
                      minWidth: "3rem",
                      color: item.status === 3 ? "white" : "black",
                      fontWeight: "800",
                      height: "3rem",
                      minHeight: "3rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className="text-center"
                    key={index}
                  >
                    {items.name}
                  </Col>
                ))}
              </Col>
            ))}
          </Col>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn-select-seat-select"
            onClick={() => setModalSeatMap(false)}
          >
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
      {!imageLoaded && <LoadingScreen />}

      <Button
        className="btn-goto-wa"
        onClick={() =>
          getLink(
            `https://api.whatsapp.com/send/?phone=${csNumber}&text=Halo+Admin+Soul%2C+Saya+ingin+bertanya+tentang+Soul&type=phone_number&app_absent=0`
          )
        }
      />

      <Col className="text-center">
        <Image
          className="img-hero"
          src={require("../images/soul/soul-logo.png")}
          loading="lazy"
        />
      </Col>
      <Col className="text-center">
        <AnimationTitles>
          <motion.span>
            <h6
              style={{
                fontSize: "0.9rem",
                fontWeight: "400",
                color: "rgba(255, 227, 120, 1)",
              }}
            >
              <FontAwesomeIcon
                icon={faCalendar}
                style={{ marginRight: "5px" }}
              />
              SATURDAY, SEPTEMBER 14th 2024
            </h6>
            <h6
              style={{ fontSize: "0.9rem", fontWeight: "400" }}
              className="mb-3"
            >
              <FontAwesomeIcon icon={faMapPin} style={{ marginRight: "5px" }} />
              THE KASABLANKA HALL, SOUTH JAKARTA
            </h6>
            <h6
              style={{
                fontSize: "0.9rem",
                border: "1px solid white",
                paddingBlock: "0.3rem",
                paddingInline: "1rem",
                borderRadius: "20px",
                fontWeight: "800",
              }}
              className="mb-0 text-center"
            >
              OPEN GATE : 17.00
            </h6>
          </motion.span>
        </AnimationTitles>
      </Col>
      <Col xs={4} className="text-end">
        <AnimationTitles>
          <motion.span>
            <Suspense fallback={<div></div>}>
              <LazyImage
                className="img-icon-bersama"
                src={require("../images/soul/soul-artis.png")}
                onLoad={handleImageLoad}
              />
            </Suspense>
          </motion.span>
        </AnimationTitles>
      </Col>
      <Col className="text-center py-2">
        <Image
          src={require("../images/soul/seat_layout.png")}
          style={{ width: "90%" }}
          className="mb-4"
        />
      </Col>
      <Col className="ticket-box">
        <Form onSubmit={handleBooking}>
          <Form.Label>Email</Form.Label>
          <InputGroup className="mb-1">
            <Form.Control
              type="email"
              id="email"
              className="input-email mb-4"
              placeholder="Masukkan e-mail"
              required
            />
          </InputGroup>
          <Button type="submit" className="btn-buy-now-ticket">
            PERIKSA BOOKING
          </Button>
        </Form>
      </Col>
      {bookingStatus && (
        <>
          <Col className="booking-detail mb-4">
            <h5 className="mb-3">Booking Info</h5>
            <h5>
              Email : <span className="text-white">{customerData?.email}</span>
            </h5>
            <h5>
              Name : <span className="text-white">{customerData?.name}</span>
            </h5>
          </Col>

          {seatBooked.length > 0 && (
            <Col className="text-center">
              <Image
                src={require("../images/soul/seat_picked.png")}
                className="mb-3"
                style={{ width: "60%" }}
              />
              <Col>
                <Row className="px-3 text-start">
                  <Col xs={4} className="seat_pick_ul_line">
                    Category
                  </Col>
                  <Col xs={8} className="seat_pick_ul">
                    Seats
                  </Col>
                </Row>
                {seatBooked?.map((item, i) => {
                  const items = var_name.find((is) => is.id === item.ticket_id);

                  return (
                    <Row className="px-3 text-start" key={i}>
                      <Col xs={4} className="seat_pick_ul_s_line">
                        {items.name}
                      </Col>
                      <Col
                        xs={8}
                        className="seat_pick_ul_s"
                        style={{ overflowX: "auto" }}
                      >
                        {`${item.seats}, `}
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Col>
          )}
          {/* <Col className="text-center">
            <Image
              src={require("../images/soul/pick_seat.png")}
              className="mb-3 mt-5"
              style={{ width: "80%" }}
            />
          </Col>
          <Col className="py-3">
            <Tabs
              defaultActiveKey="profile"
              className="mb-3 tabs-ticket"
              activeKey={key}
              onSelect={(k) => [setKey(k), getRecomendation(k)]}
              fill
            >
              {bookingData?.map((item, i) => (
                <Tab
                  key={i}
                  eventKey={`${item.ticket_id}-${item.booking_code}`}
                  title={
                    <>
                      <h6 style={{ marginBlock: "-1px" }}>
                        <b>{item.name}</b>
                      </h6>
                      <span>({item.allocated_seat} Seat)</span>
                    </>
                  }
                >
                  <Table responsive="xl" bordered striped>
                    <thead>
                      <tr>
                        <th className="th-white-no">Aksi</th>
                        <th className="th-white">Rekomendasi Kursi</th>
                        <th className="th-white">Total Reservasi</th>
                        <th className="th-white-no">Diskon</th>
                      </tr>
                    </thead>
                    <tbody>
                      {optionSeat?.map((item, i) => (
                        <tr key={i}>
                          <td className="text-center">
                            <Button
                              className={`btn-select-seat${
                                item[0].name === selectedSeat ? "-select" : ""
                              }`}
                              onClick={() =>
                                handleSelectSeat(
                                  item[0].name,
                                  getAllNamesNoLoc(item),
                                  item[0].ticket_id,
                                  getTotalPrice(item),
                                  item
                                )
                              }
                            >
                              Pilih
                            </Button>
                          </td>
                          <td>
                            <b>{getAllNames(item)}</b>
                            <br />
                            <Button
                              className="mt-3 btn-map-view"
                              onClick={() => [
                                getSeatMap(
                                  item[0].ticket_id,
                                  item[0].loc2stage
                                ),
                                getAllId(item),
                              ]}
                            >
                              Lihat Denah
                            </Button>
                          </td>
                          <td>Rp {formatNumberWithout(getTotalPrice(item))}</td>
                          <td>Rp 0</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Tab>
              ))}
            </Tabs>
          </Col>
          <Col ref={section3Ref}>
            <p>
              <i>
                *Batas reservasi hanya{" "}
                <span className="text-danger">
                  <b>15 menit</b>
                </span>{" "}
                sampai waktu pembayaran habis
              </i>
            </p>
            <p>
              <i>
                *Jika Anda prefer untuk tidak memilih kursi, sistem akan secara
                otomatis menentukan nomor kursi <b>(FREE)</b>
              </i>
            </p>
            <p>
              <i>
                {
                  "*Invoice reservasi kursi akan dikirim ke email anda setelah dipesan :)"
                }
              </i>
            </p>
            <Form.Label>Kursi Dipilih</Form.Label>
            <Form.Control
              className="input-email mb-3"
              readOnly={true}
              value={listSeatSelected}
            />
            <Form.Label>Total Reservasi</Form.Label>
            <Form.Control
              className="input-email mb-4 "
              readOnly={true}
              value={`Rp ${formatNumberWithout(priceSeatSelected)}`}
            />
            <Button
              className="btn-buy-now-ticket"
              disabled={disableRes}
              onClick={() => handleReservSeat()}
            >
              Reservasi Kursi
            </Button>
          </Col> */}
        </>
      )}
    </Container>
  );
};

export default Home;
