const var_name = [
  {
    id: 3,
    name: "CAT 1",
  },
  {
    id: 4,
    name: "CAT 2",
  },
  {
    id: 5,
    name: "CAT 3",
  },
  {
    id: 6,
    name: "CAT 4",
  },
  {
    id: 7,
    name: "CAT 5",
  },
  {
    id: 8,
    name: "CAT 6",
  },
  {
    id: 9,
    name: "CAT 7",
  },
  {
    id: 10,
    name: "CAT 8",
  },
];

export { var_name };
